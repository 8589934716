"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Unified Communications Service
 * API for management of communications services/channels such as SMS, Fax, Push Notifications, and Email.
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.SupportedOrderBy = exports.Order = exports.Method = exports.MessageCode = exports.Language = exports.AuthorType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AuthorType = {
    Patient: 'patient',
    Pharmacy: 'pharmacy'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Language = {
    En: 'en',
    Es: 'es',
    Fr: 'fr'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.MessageCode = {
    DeliveryLocal: 'delivery_local',
    DeliveryShipped: 'delivery_shipped',
    PrescriptionReady: 'prescription_ready',
    RefillReminder: 'refill_reminder',
    ReturnToStock: 'return_to_stock',
    WelcomeMessage: 'welcome_message',
    BirthdayMessage: 'birthday_message',
    InsuranceCardNeeded: 'insurance_card_needed',
    NoRefills: 'no_refills',
    OutOfStock: 'out_of_stock',
    PriorAuthorizationNeeded: 'prior_authorization_needed',
    CustomMessage: 'custom_message',
    CustomTemplate: 'custom_template'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Method = {
    Email: 'email',
    Sms: 'sms',
    Push: 'push',
    Fax: 'fax',
    Desktop: 'desktop',
    Voice: 'voice'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Order = {
    Asc: 'asc',
    Desc: 'desc'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SupportedOrderBy = {
    FirstName: 'first_name',
    MostRecentQualifyingMessageDate: 'most_recent_qualifying_message_date'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {AddDirectMessageDto} addDirectMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageAddMessage: function (xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageAddMessage', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageAddMessage', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageAddMessage', 'locationPatientId', locationPatientId);
                    // verify required parameter 'conversationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageAddMessage', 'conversationId', conversationId);
                    // verify required parameter 'addDirectMessageDto' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageAddMessage', 'addDirectMessageDto', addDirectMessageDto);
                    localVarPath = "/direct-message/locations/{location_id}/patients/{location_patient_id}/conversations/{conversation_id}/messages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)))
                        .replace("{".concat("conversation_id", "}"), encodeURIComponent(String(conversationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(addDirectMessageDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {CreateConversationDto} createConversationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageCreateConversation: function (xPharmacyId, locationId, locationPatientId, createConversationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageCreateConversation', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageCreateConversation', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageCreateConversation', 'locationPatientId', locationPatientId);
                    // verify required parameter 'createConversationDto' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageCreateConversation', 'createConversationDto', createConversationDto);
                    localVarPath = "/direct-message/locations/{location_id}/patients/{location_patient_id}/conversations"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createConversationDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {DirectMessageConversationFiltersDto} directMessageConversationFiltersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageFilterConversationList: function (xPharmacyId, locationId, directMessageConversationFiltersDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageFilterConversationList', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageFilterConversationList', 'locationId', locationId);
                    // verify required parameter 'directMessageConversationFiltersDto' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageFilterConversationList', 'directMessageConversationFiltersDto', directMessageConversationFiltersDto);
                    localVarPath = "/direct-message/locations/{location_id}/filter"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(directMessageConversationFiltersDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllConversationsByPatient: function (xPharmacyId, locationId, locationPatientId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllConversationsByPatient', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllConversationsByPatient', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllConversationsByPatient', 'locationPatientId', locationPatientId);
                    localVarPath = "/direct-message/locations/{location_id}/patients/{location_patient_id}/conversations"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {SupportedOrderBy} [orderBy]
         * @param {Order} [order]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllConversationsByPatientAndLocation: function (xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllConversationsByPatientAndLocation', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllConversationsByPatientAndLocation', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllConversationsByPatientAndLocation', 'locationPatientId', locationPatientId);
                    localVarPath = "/direct-message/locations/{location_id}/conversations/patients/{location_patient_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (orderBy !== undefined) {
                        localVarQueryParameter['orderBy'] = orderBy;
                    }
                    if (order !== undefined) {
                        localVarQueryParameter['order'] = order;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {number} [skip]
         * @param {number} [take]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllMessagesByConversation: function (xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllMessagesByConversation', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllMessagesByConversation', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllMessagesByConversation', 'locationPatientId', locationPatientId);
                    // verify required parameter 'conversationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllMessagesByConversation', 'conversationId', conversationId);
                    localVarPath = "/direct-message/locations/{location_id}/patients/{location_patient_id}/conversations/{conversation_id}/messages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)))
                        .replace("{".concat("conversation_id", "}"), encodeURIComponent(String(conversationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (skip !== undefined) {
                        localVarQueryParameter['skip'] = skip;
                    }
                    if (take !== undefined) {
                        localVarQueryParameter['take'] = take;
                    }
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllPatientsWithConversationsByLocation: function (xPharmacyId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllPatientsWithConversationsByLocation', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetAllPatientsWithConversationsByLocation', 'locationId', locationId);
                    localVarPath = "/direct-message/locations/{location_id}/conversations/patients"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetConversation: function (xPharmacyId, locationId, locationPatientId, conversationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetConversation', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetConversation', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetConversation', 'locationPatientId', locationPatientId);
                    // verify required parameter 'conversationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetConversation', 'conversationId', conversationId);
                    localVarPath = "/direct-message/locations/{location_id}/patients/{location_patient_id}/conversations/{conversation_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)))
                        .replace("{".concat("conversation_id", "}"), encodeURIComponent(String(conversationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetNotViewedChatsCountByPharmacy: function (xPharmacyId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetNotViewedChatsCountByPharmacy', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageGetNotViewedChatsCountByPharmacy', 'locationId', locationId);
                    localVarPath = "/direct-message/locations/{location_id}/new_chat_count"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {UpdateUserViewedStatusDto} updateUserViewedStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageUpdateUserViewedStatus: function (xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageUpdateUserViewedStatus', 'xPharmacyId', xPharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageUpdateUserViewedStatus', 'locationId', locationId);
                    // verify required parameter 'locationPatientId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageUpdateUserViewedStatus', 'locationPatientId', locationPatientId);
                    // verify required parameter 'conversationId' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageUpdateUserViewedStatus', 'conversationId', conversationId);
                    // verify required parameter 'updateUserViewedStatusDto' is not null or undefined
                    (0, common_1.assertParamExists)('directMessageUpdateUserViewedStatus', 'updateUserViewedStatusDto', updateUserViewedStatusDto);
                    localVarPath = "/direct-message/locations/{location_id}/patients/{location_patient_id}/conversations/{conversation_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("location_patient_id", "}"), encodeURIComponent(String(locationPatientId)))
                        .replace("{".concat("conversation_id", "}"), encodeURIComponent(String(conversationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateUserViewedStatusDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/integrationsCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Send a patient a message from a pharmacy location
         * @param {string} locationId
         * @param {string} patientId
         * @param {PatientLocationMessageDto} patientLocationMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreatePatientLocationMessage: function (locationId, patientId, patientLocationMessageDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageCreatePatientLocationMessage', 'locationId', locationId);
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('messageCreatePatientLocationMessage', 'patientId', patientId);
                    // verify required parameter 'patientLocationMessageDto' is not null or undefined
                    (0, common_1.assertParamExists)('messageCreatePatientLocationMessage', 'patientLocationMessageDto', patientLocationMessageDto);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/messages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientLocationMessageDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Send a phamacy location a message from a patient
         * @param {string} locationId
         * @param {PharmacyLocationMessageDto} pharmacyLocationMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreatePharmacyLocationMessage: function (locationId, pharmacyLocationMessageDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageCreatePharmacyLocationMessage', 'locationId', locationId);
                    // verify required parameter 'pharmacyLocationMessageDto' is not null or undefined
                    (0, common_1.assertParamExists)('messageCreatePharmacyLocationMessage', 'pharmacyLocationMessageDto', pharmacyLocationMessageDto);
                    localVarPath = "/locations/{location_id}/messages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyLocationMessageDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Create a new Message Template for the given location
         * @param {string} locationId
         * @param {CreateMessageTemplateDto} createMessageTemplateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateCreate: function (locationId, createMessageTemplateDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateCreate', 'locationId', locationId);
                    // verify required parameter 'createMessageTemplateDto' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateCreate', 'createMessageTemplateDto', createMessageTemplateDto);
                    localVarPath = "/locations/{location_id}/message_templates"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createMessageTemplateDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Retrieve all Message Templates for the given location
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateFindAll: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateFindAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/message_templates"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Retrieve a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateFindOne: function (locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateFindOne', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateFindOne', 'id', id);
                    localVarPath = "/locations/{location_id}/message_templates/{id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateRemove: function (locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateRemove', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateRemove', 'id', id);
                    localVarPath = "/locations/{location_id}/message_templates/{id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete all Message Templates for the given location
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateRemoveAll: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateRemoveAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/message_templates"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateMessageTemplateDto} updateMessageTemplateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateUpdate: function (locationId, id, updateMessageTemplateDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateUpdate', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateUpdate', 'id', id);
                    // verify required parameter 'updateMessageTemplateDto' is not null or undefined
                    (0, common_1.assertParamExists)('messageTemplateUpdate', 'updateMessageTemplateDto', updateMessageTemplateDto);
                    localVarPath = "/locations/{location_id}/message_templates/{id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateMessageTemplateDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHealthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {AddDirectMessageDto} addDirectMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageAddMessage: function (xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageAddMessage(xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {CreateConversationDto} createConversationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageCreateConversation: function (xPharmacyId, locationId, locationPatientId, createConversationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageCreateConversation(xPharmacyId, locationId, locationPatientId, createConversationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {DirectMessageConversationFiltersDto} directMessageConversationFiltersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageFilterConversationList: function (xPharmacyId, locationId, directMessageConversationFiltersDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageFilterConversationList(xPharmacyId, locationId, directMessageConversationFiltersDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllConversationsByPatient: function (xPharmacyId, locationId, locationPatientId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageGetAllConversationsByPatient(xPharmacyId, locationId, locationPatientId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {SupportedOrderBy} [orderBy]
         * @param {Order} [order]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllConversationsByPatientAndLocation: function (xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageGetAllConversationsByPatientAndLocation(xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {number} [skip]
         * @param {number} [take]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllMessagesByConversation: function (xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageGetAllMessagesByConversation(xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllPatientsWithConversationsByLocation: function (xPharmacyId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageGetAllPatientsWithConversationsByLocation(xPharmacyId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetConversation: function (xPharmacyId, locationId, locationPatientId, conversationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageGetConversation(xPharmacyId, locationId, locationPatientId, conversationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetNotViewedChatsCountByPharmacy: function (xPharmacyId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageGetNotViewedChatsCountByPharmacy(xPharmacyId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {UpdateUserViewedStatusDto} updateUserViewedStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageUpdateUserViewedStatus: function (xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.directMessageUpdateUserViewedStatus(xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsGetIntegrationCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send a patient a message from a pharmacy location
         * @param {string} locationId
         * @param {string} patientId
         * @param {PatientLocationMessageDto} patientLocationMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreatePatientLocationMessage: function (locationId, patientId, patientLocationMessageDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageCreatePatientLocationMessage(locationId, patientId, patientLocationMessageDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send a phamacy location a message from a patient
         * @param {string} locationId
         * @param {PharmacyLocationMessageDto} pharmacyLocationMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreatePharmacyLocationMessage: function (locationId, pharmacyLocationMessageDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageCreatePharmacyLocationMessage(locationId, pharmacyLocationMessageDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create a new Message Template for the given location
         * @param {string} locationId
         * @param {CreateMessageTemplateDto} createMessageTemplateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateCreate: function (locationId, createMessageTemplateDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageTemplateCreate(locationId, createMessageTemplateDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve all Message Templates for the given location
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateFindAll: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageTemplateFindAll(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Retrieve a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateFindOne: function (locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageTemplateFindOne(locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateRemove: function (locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageTemplateRemove(locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete all Message Templates for the given location
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateRemoveAll: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageTemplateRemoveAll(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateMessageTemplateDto} updateMessageTemplateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateUpdate: function (locationId, id, updateMessageTemplateDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.messageTemplateUpdate(locationId, id, updateMessageTemplateDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return localVarFp.appGetHealthCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {AddDirectMessageDto} addDirectMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageAddMessage: function (xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options) {
            return localVarFp.directMessageAddMessage(xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {CreateConversationDto} createConversationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageCreateConversation: function (xPharmacyId, locationId, locationPatientId, createConversationDto, options) {
            return localVarFp.directMessageCreateConversation(xPharmacyId, locationId, locationPatientId, createConversationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {DirectMessageConversationFiltersDto} directMessageConversationFiltersDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageFilterConversationList: function (xPharmacyId, locationId, directMessageConversationFiltersDto, options) {
            return localVarFp.directMessageFilterConversationList(xPharmacyId, locationId, directMessageConversationFiltersDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllConversationsByPatient: function (xPharmacyId, locationId, locationPatientId, options) {
            return localVarFp.directMessageGetAllConversationsByPatient(xPharmacyId, locationId, locationPatientId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {SupportedOrderBy} [orderBy]
         * @param {Order} [order]
         * @param {number} [limit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllConversationsByPatientAndLocation: function (xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options) {
            return localVarFp.directMessageGetAllConversationsByPatientAndLocation(xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {number} [skip]
         * @param {number} [take]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllMessagesByConversation: function (xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options) {
            return localVarFp.directMessageGetAllMessagesByConversation(xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetAllPatientsWithConversationsByLocation: function (xPharmacyId, locationId, options) {
            return localVarFp.directMessageGetAllPatientsWithConversationsByLocation(xPharmacyId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetConversation: function (xPharmacyId, locationId, locationPatientId, conversationId, options) {
            return localVarFp.directMessageGetConversation(xPharmacyId, locationId, locationPatientId, conversationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageGetNotViewedChatsCountByPharmacy: function (xPharmacyId, locationId, options) {
            return localVarFp.directMessageGetNotViewedChatsCountByPharmacy(xPharmacyId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} xPharmacyId
         * @param {string} locationId
         * @param {string} locationPatientId
         * @param {string} conversationId
         * @param {UpdateUserViewedStatusDto} updateUserViewedStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directMessageUpdateUserViewedStatus: function (xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options) {
            return localVarFp.directMessageUpdateUserViewedStatus(xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return localVarFp.integrationsGetIntegrationCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send a patient a message from a pharmacy location
         * @param {string} locationId
         * @param {string} patientId
         * @param {PatientLocationMessageDto} patientLocationMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreatePatientLocationMessage: function (locationId, patientId, patientLocationMessageDto, options) {
            return localVarFp.messageCreatePatientLocationMessage(locationId, patientId, patientLocationMessageDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send a phamacy location a message from a patient
         * @param {string} locationId
         * @param {PharmacyLocationMessageDto} pharmacyLocationMessageDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageCreatePharmacyLocationMessage: function (locationId, pharmacyLocationMessageDto, options) {
            return localVarFp.messageCreatePharmacyLocationMessage(locationId, pharmacyLocationMessageDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create a new Message Template for the given location
         * @param {string} locationId
         * @param {CreateMessageTemplateDto} createMessageTemplateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateCreate: function (locationId, createMessageTemplateDto, options) {
            return localVarFp.messageTemplateCreate(locationId, createMessageTemplateDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve all Message Templates for the given location
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateFindAll: function (locationId, options) {
            return localVarFp.messageTemplateFindAll(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Retrieve a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateFindOne: function (locationId, id, options) {
            return localVarFp.messageTemplateFindOne(locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateRemove: function (locationId, id, options) {
            return localVarFp.messageTemplateRemove(locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete all Message Templates for the given location
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateRemoveAll: function (locationId, options) {
            return localVarFp.messageTemplateRemoveAll(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update a Message Template for the given location
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateMessageTemplateDto} updateMessageTemplateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        messageTemplateUpdate: function (locationId, id, updateMessageTemplateDto, options) {
            return localVarFp.messageTemplateUpdate(locationId, id, updateMessageTemplateDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.appGetHealthCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).appGetHealthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {string} conversationId
     * @param {AddDirectMessageDto} addDirectMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageAddMessage = function (xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageAddMessage(xPharmacyId, locationId, locationPatientId, conversationId, addDirectMessageDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {CreateConversationDto} createConversationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageCreateConversation = function (xPharmacyId, locationId, locationPatientId, createConversationDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageCreateConversation(xPharmacyId, locationId, locationPatientId, createConversationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {DirectMessageConversationFiltersDto} directMessageConversationFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageFilterConversationList = function (xPharmacyId, locationId, directMessageConversationFiltersDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageFilterConversationList(xPharmacyId, locationId, directMessageConversationFiltersDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageGetAllConversationsByPatient = function (xPharmacyId, locationId, locationPatientId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageGetAllConversationsByPatient(xPharmacyId, locationId, locationPatientId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {SupportedOrderBy} [orderBy]
     * @param {Order} [order]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageGetAllConversationsByPatientAndLocation = function (xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageGetAllConversationsByPatientAndLocation(xPharmacyId, locationId, locationPatientId, orderBy, order, limit, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {string} conversationId
     * @param {number} [skip]
     * @param {number} [take]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageGetAllMessagesByConversation = function (xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageGetAllMessagesByConversation(xPharmacyId, locationId, locationPatientId, conversationId, skip, take, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageGetAllPatientsWithConversationsByLocation = function (xPharmacyId, locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageGetAllPatientsWithConversationsByLocation(xPharmacyId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {string} conversationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageGetConversation = function (xPharmacyId, locationId, locationPatientId, conversationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageGetConversation(xPharmacyId, locationId, locationPatientId, conversationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageGetNotViewedChatsCountByPharmacy = function (xPharmacyId, locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageGetNotViewedChatsCountByPharmacy(xPharmacyId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} xPharmacyId
     * @param {string} locationId
     * @param {string} locationPatientId
     * @param {string} conversationId
     * @param {UpdateUserViewedStatusDto} updateUserViewedStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.directMessageUpdateUserViewedStatus = function (xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).directMessageUpdateUserViewedStatus(xPharmacyId, locationId, locationPatientId, conversationId, updateUserViewedStatusDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.integrationsGetIntegrationCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).integrationsGetIntegrationCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send a patient a message from a pharmacy location
     * @param {string} locationId
     * @param {string} patientId
     * @param {PatientLocationMessageDto} patientLocationMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageCreatePatientLocationMessage = function (locationId, patientId, patientLocationMessageDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageCreatePatientLocationMessage(locationId, patientId, patientLocationMessageDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send a phamacy location a message from a patient
     * @param {string} locationId
     * @param {PharmacyLocationMessageDto} pharmacyLocationMessageDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageCreatePharmacyLocationMessage = function (locationId, pharmacyLocationMessageDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageCreatePharmacyLocationMessage(locationId, pharmacyLocationMessageDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create a new Message Template for the given location
     * @param {string} locationId
     * @param {CreateMessageTemplateDto} createMessageTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageTemplateCreate = function (locationId, createMessageTemplateDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageTemplateCreate(locationId, createMessageTemplateDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve all Message Templates for the given location
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageTemplateFindAll = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageTemplateFindAll(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Retrieve a Message Template for the given location
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageTemplateFindOne = function (locationId, id, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageTemplateFindOne(locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete a Message Template for the given location
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageTemplateRemove = function (locationId, id, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageTemplateRemove(locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete all Message Templates for the given location
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageTemplateRemoveAll = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageTemplateRemoveAll(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update a Message Template for the given location
     * @param {string} locationId
     * @param {string} id
     * @param {UpdateMessageTemplateDto} updateMessageTemplateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.messageTemplateUpdate = function (locationId, id, updateMessageTemplateDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).messageTemplateUpdate(locationId, id, updateMessageTemplateDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
